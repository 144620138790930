import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/css/style.css';
import './assets/css/mediaqueries.css';

import Home from './components/Home';
import LetsTalk from './components/LetsTalk';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in',
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <Router>
      <div className="App">
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lets_talk" element={<LetsTalk />} />
        </Routes>
        
      </div>
    </Router>
  );
};

export default App;
