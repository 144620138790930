// FooterSection.js
import React from 'react';


 const Footer = () => {
    return (
        <section className="footer-section" id="footer_section">
            <div className="container">
                <div className="middle-portion">
                    <div className="row">
                        {/* About Us Column */}
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="about_col">
                                <a href="index.html">
                                    <figure>
                                        <img src={require('../assets/images/Cyberbells_ai_logor.png')} alt="Footer Logo" className="img-fluid" />
                                    </figure>
                                </a>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <p className="text-size-16">
                                        Artificial Intelligence refers to computer <br/>systems designed to mimic human <br/>intelligence.
                                        </p>
                                    </li>
                                    <li className="icons hover-effect">
                                        <a href="https://www.facebook.com/profile.php?id=61558140705210"  target="_blank"><i className="fa-brands fa-facebook-f" aria-hidden="true"></i></a>
                                    </li>
                                    {/* <li className="icons hover-effect">
                                        <a href="#"><i className="fa-brands fa-twitter" aria-hidden="true"></i></a>
                                    </li> */}
                                    <li className="icons hover-effect">
                                        <a href="https://www.linkedin.com/company/cyberbells-ites-services-ltd/?viewAsMember=true" target="_blank"><i className="fa-brands fa-linkedin-in mr-0" aria-hidden="true"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* Useful Links Column */}
                        {/* <div className="col-lg-2 col-md-4 col-sm-3 col-xs-12 d-md-block d-none">
                            <div className="links_col">
                                <h4>Useful Links</h4>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <span></span>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <span></span>
                                        <a href="#about">About</a>
                                    </li>
                                    <li>
                                        <span></span>
                                        <a href="#services">Services</a>
                                    </li>
                                    <li>
                                        <span></span>
                                        <a href="#projects">Projects</a>
                                    </li>
                                    <li>
                                        <span></span>
                                        <a href="#contact">Contact us</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}

                        {/* Contact Us Column */}
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 d-sm-block d-none">
                            <div className="contact_col">
                                <h4>Contact Us</h4>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <i className="fa-solid fa-phone"></i>
                                        <a href="tel:+91-98787-87591" className="text-decoration-none">+91-98787-87591</a>
                                    </li>
                                    <li className="contact_mail">
                                        <i className="fa-sharp fa-solid fa-envelope"></i>
                                        <a href="mailto:info@cyberbells.com" className="text-decoration-none">info@cyberbells.com</a>
                                    </li>
                                    <li className="mb-0">
                                        <i className="fa-solid fa-location-dot location"></i>
                                        <span className="text-size-16">Address: S.C.O. 11, Industrial Cum Business Park, Phase II, Chandigarh</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* Newsletter Column */}
                        {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12 text-sm-left text-center d-lg-block d-none">
                            <div className="footer-info-content">
                                <h4>Newsletter</h4>
                                <input type="email" name="email" id="emailadd" className="form-control" placeholder="Enter Email:" />
                                <div className="input-group-append form-button">
                                    <button className="btn" name="btnsubmit" id="submitbtn" type="submit">
                                        <i className="fa-solid fa-paper-plane"></i>
                                    </button>
                                </div>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <input type="checkbox" id="check" name="check" />
                                        <span>Subscribe to our AI Insights newsletter.</span>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* Footer Background Shapes */}
                <div className="footer_right_shape top_bottom_shape">
                    <figure className="mb-0">
                        <img src={require('../assets/images/footer_right_shape.png')} alt="Footer Right Shape" className="img-fluid" />
                    </figure>
                </div>
                <div className="footer_background_shape left_right_shape">
                    <figure className="mb-0">
                        <img src={require('../assets/images/footer_background_shape.jpg')} alt="Footer Background Shape" />
                    </figure>
                </div>
            </div>

            {/* Footer Bottom Portion */}
            <div className="bottom-portion">
                <div className="copyright col-xl-12">
                    <p>Copyright © 2024 Cyberbells ITES Services Pvt Ltd. All rights reserved.</p>
                </div>
            </div>
        </section>
    );
};

export default Footer;
