import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import '../assets/css/StatisticsSection.css';

const StatisticsSection = () => {
    const props = useSpring({
        from: { number: 1 },
        to: { number: 39 },
        config: { duration: 5000, 
            easing: (t) => (t === 0) ? 0 : (t === 1) ? 1 : (t < 0.5) ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 },
        // onChange: ({ value }) => console.log(value.number),
    });

    const props2 = useSpring({
        from: { number: 1 },
        to: { number: 20 },
        config: { duration: 5000, 
            easing: (t) => (t === 0) ? 0 : (t === 1) ? 1 : (t < 0.5) ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 },
        // onChange: ({ value }) => console.log(value.number),
    });
    const props3 = useSpring({
        from: { number: 1 },
        to: { number: 11 },
        config: { duration: 5000,
             easing: (t) => (t === 0) ? 0 : (t === 1) ? 1 : (t < 0.5) ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 },
        // onChange: ({ value }) => console.log(value.number),
    });

    return (
        <section className="statistics_section position-relative">
            <div className="container">
                <div className="row" data-aos="fade-up">
                    {/* Statistics Content */}
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <div className="statistics_content">
                            <h3 className="mb-0">Empowering The Future With AI.</h3>
                        </div>
                    </div>
                    {/* Project Completed */}
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="statistics_box hover-effect">
                            <div className="span_wrapper">
                                <span className="number counter">
                                    <animated.div>
                                        {props.number.to(n => Math.floor(n))}
                                    </animated.div>
                                </span>
                                <span className="plus">K+</span>
                            </div>
                            <p className="text-size-16 text-white pp">Project Completed</p>
                            <p className="text-size-16 mb-0 text">We take pride in our track record of success, having successfully completed projects for our discerning clientele. </p>
                        </div>
                    </div>
                    {/* Happy Clients */}
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="statistics_box hover-effect">
                            <div className="span_wrapper">
                                <span className="number counter">
                                    <animated.div>
                                        {props2.number.to(n => Math.floor(n))}
                                    </animated.div>
                                </span>
                                <span className="plus">K+</span>
                            </div>
                            <p className="text-size-16 text-white pp">Happy Clients</p>
                            <p className="text-size-16 mb-0 text">This remarkable milestone reflects our commitment to revolutionizing the Ai experience through service.</p>
                        </div>
                    </div>
                    {/* Years of Experience */}
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="statistics_box hover-effect">
                            <div className="span_wrapper"> 
                                <span className="number counter">
                                    <animated.div>
                                        {props3.number.to(n => Math.floor(n))}
                                    </animated.div>
                                </span>
                                <span className="plus">K+</span>

                            </div>
                            <p className="text-size-16 text-white pp">Years of Experience</p>
                            <p className="text-size-16 mb-0 text">This collaborative expertise has resulted in groundbreaking applications of machine learning in gemstone analysis. </p>
                        </div>
                    </div>
                </div>
                <figure className="statistics_left_shape mb-0 position-absolute top_bottom_shape">
                    <img src={require('../assets/images/statistics_left_shape.png')} alt="Statistics Left Shape" className="img-fluid" />
                </figure>
                <figure className="statistics_right_shape mb-0 position-absolute top_bottom_shape">
                    <img src={require('../assets/images/statistics_right_shape.png')} alt="Statistics Right Shape" className="img-fluid" />
                </figure>
            </div>
        </section>
    );
};

export default StatisticsSection;
