import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../assets/css/letsTalk.css';
import avatar3 from '../assets/images/avatar3.png'; // Import the image
import Footer from './Footer';

const LetsTalk = () => {
  const [chatMessages] = useState([
    { type: 'left', name: 'Russell', avatar: avatar3, text: "Hello, I'm Russell. How can I help you today?", time: '08:55' }
  ]);

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const handleNextMessage = () => {
    setCurrentMessageIndex((currentMessageIndex + 1) % chatMessages.length);
  };

  const currentMessage = chatMessages[currentMessageIndex];

  return (
    <>
        <div className="banner-section-outer position-relative">

      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand" to="/">
              <figure className="mb-0">
                <img src={require('../assets/images/Cyberbells_ai_logor.png')} alt="Cyberbells Logo" className="img-fluid" />
              </figure>
            </Link>
            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link scrollto" href="#home#about">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scrollto" href="#services">Services</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scrollto" href="#portfolio">Portfolio</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scrollto" href="#contact">Contact</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link talk_btn" to="">Let’s Talk <i className="fa-solid fa-angle-right"></i></Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <div className="container">
        <div className="content-wrapper">
          <div className="row gutters justify-content-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              {/* <div className="card m-0"> */}
                {/* <div className="selected-user text-center">
                  <h4 className="name mt-2">Cyberbells AI Chat bot</h4>
                </div> */}
                <div className="chat-container">
                  <ul className="chat-box chatContainerScroll">
                    <li className={`chat-${currentMessage.type}`}>
                      <div className="chat-avatar">
                        <img src={currentMessage.avatar} alt={currentMessage.name} />
                        <div className="chat-name">{currentMessage.name}</div>
                      </div>
                      <div className="chat-text">
                        {currentMessage.text.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="chat-hour">{currentMessage.time} <span className="fa fa-check-circle"></span></div>
                    </li>
                  </ul>
                  <div className="chat-search-box">
                    <div className="input-group">
                      <textarea className="form-control" rows="1" placeholder="Type your message here..."></textarea>
                      <div className="input-group-btn">
                        <button type="button" className="btn btn-info" onClick={handleNextMessage}>
                          Next <i className="fa fa-location-arrow"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              {/* </div> */}

            </div>
          </div>
        </div>
      </div>
      <Footer />
      </div>
    </>
  );
};

export default LetsTalk;
