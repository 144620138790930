import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/PortfolioSection.css';
import '../assets/css/style.css';
import portfolioLeftShape from '../assets/images/portfolio_left_shape.png';
import { Modal, Button } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

// const PROJECTS_API_URL = 'http://95.216.209.46:9000/projects';
const PROJECTS_API_URL = `${process.env.REACT_APP_BASE_URL}/projects`;


const PortfolioSection = () => {
    const [portfolioItems, setPortfolioItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const fetchProjects = async () => {
        try {
            const response = await fetch(PROJECTS_API_URL);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPortfolioItems(data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleReadMoreClick = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    return (
        <section className="portfolio_section" id="portfolio">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="portfolio_content">
                            <h6>Portfolio</h6>
                            <h2>Our Latest Projects Showcase</h2>
                        </div>
                    </div>
                </div>
                <div className="row" data-aos="fade-up">
                    <Slider {...settings}>
                        {portfolioItems.map((item, index) => (
                            <div className="item" key={index}>
                                <div className="portfolio_image">
                                    <figure className="mb-0">
                                        <img src={`http://localhost:9000${item.image}`} alt={item.name} className="img-flui" style={{height: '300px', width: '250px'}}/>
                                    </figure>
                                    <div className="portfolio_box">
                                        <p>{item.description}</p>
                                        <div className="btn_wrapper">
                                            <Button variant="link" onClick={() => handleReadMoreClick(item)} className="text-decoration-none">
                                                Read More<i className="fa-solid fa-angle-right"></i>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="portfolio_image_box">
                                        <span>{item.name}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <figure className="portfolio_left_shape mb-0 position-absolute top_bottom_shape">
                    <img src={portfolioLeftShape} alt="" className="img-fluid" />
                </figure>
            </div>

            <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal-dialog">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedItem?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-content modal-body-custom">
                <div className="row">
                        <div className="col-md-5">
                            <img
                                src={`http://localhost:9000${selectedItem?.image}`}
                                alt={selectedItem?.name}
                                className="img-fluid" 
                            />
                        </div>
                        <div className="col-md-6">
                            <p><b>Description:</b></p>
                            <p>{selectedItem?.description}</p>
                            
                            {selectedItem?.url ? (
                                <Button variant="success" href={selectedItem.url} target="_blank">
                                    {selectedItem.url}
                                </Button>
                            ) : (
                                <p className="text-success">
                                     Demo Video  <i className="fa fa-download"></i>
                                </p>
                            )}

                            {selectedItem?.video && (
                                <div className="col-md-7 mx-5 mt-5 position-relative">
                                    <video
                                        src={`http://localhost:9000${selectedItem?.video}`}
                                        alt={selectedItem?.name}
                                        className="img-fluid shadow-lg"
                                        controls
                                        style={{ height: '500px' }} 
                                    />
                                    {/* <div className="position-absolute top-50 start-50 translate-middle">
                                        <i className="fa fa-play fa-3x text-white" style={{ cursor: 'pointer' }}></i>
                                    </div> */}
                                </div>
                            )}

                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default PortfolioSection;
