import React from 'react';
import BannerSection from './BannerSection';
import StatisticsSection from './StatisticsSection';
import ServicesSection from './ServicesSection';
import AboutUsSection from './AboutUsSection';
import WhoWeAreSection from './WhoWeAre';
import PortfolioSection from './PortfolioSection';
import TestimonialsSection from './TestimonialsSection';
import ComboSection from './ComboSection';
import Footer from './Footer';

const Home = () => {
  return (
    <>
      <BannerSection />
      <StatisticsSection />
      <ServicesSection />
      <AboutUsSection />
      <WhoWeAreSection />
      <PortfolioSection />
      <TestimonialsSection />
      <ComboSection />
      <Footer />
    </>
  );
};

export default Home;
