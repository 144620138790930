import React, { useState } from 'react';

const ServicesSection = () => {
    const [modalContent, setModalContent] = useState({});

    const handleModalContent = (title, imgSrc, description) => {
        setModalContent({ title, imgSrc, description });
    };

    const modalStyles = {
        dialog: {
            maxWidth: '100%',
            width: '80%',
            height: '80%',
            maxHeight: '80%',
        },
        content: {
            height: '100%',
        },
    };

    return (
        <section className="services_section position-relative" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                        <div className="services_content">
                            <h6>What We Can Do</h6>
                            <h2>Services We Can Help You With</h2>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12 col-12 d-md-block d-none"></div>
                </div>
                <div className="row position-relative" data-aos="fade-up">
                    {/* Robotic Automation */}
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="services_box hover-effect">
                            <figure>
                                <img src={require('../assets/images/services_box_image1.png')} alt="Robotic Automation" className="img-fluid hover-effect" />
                            </figure>
                            <h5>Robotic Automation</h5>
                            <p>Robotic automation is transforming the way businesses operate across various sectors... </p>
                            <div className="btn_wrapper">
                            <a href="#" 
                                onClick={() => handleModalContent(
                                    'Robotic Automation', 
                                    require('../assets/images/robotic_automation.jpg'),
                                    (
                                        <div>
                                            This cutting-edge technology combines artificial intelligence, machine learning, and advanced robotics to streamline processes, increase efficiency, and reduce human error.<br/><br/>
                                            As businesses continue to adopt robotic automation, we're seeing a shift in the job market. While some roles may be replaced, new opportunities are emerging in robot maintenance, programming, and system integration.<br/><br/>
                                            The future of robotic automation looks promising, with advancements in AI and sensor technology paving the way for more sophisticated and adaptable robotic systems. As this technology evolves, we can expect to see even greater integration of robotic automation across various industries and applications.
                                        </div>
                                    )
                                    )} 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#exampleModal" 
                                    className="text-decoration-none">
                                    Read More<i className="fa-solid fa-angle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Predictive Analysis */}
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="services_box box2 hover-effect">
                            <figure>
                                <img src={require('../assets/images/services_box_image2.png')} alt="Predictive Analysis" className="img-fluid hover-effect" />
                            </figure>
                            <h5>Predictive Analysis</h5>
                            <p>Predictive analysis is a powerful tool that leverages historical data, statistical algorithms...</p>
                            <div className="btn_wrapper">
                                <a href="#" onClick={() => handleModalContent('Predictive Analysis', require('../assets/images/Predictive_Analysis.jpg'), 
                                (
                                <div>
                              Predictive analysis is a powerful tool that leverages historical data, statistical algorithms, and machine learning techniques to forecast future outcomes. This cutting-edge approach to data analysis is revolutionizing how businesses make decisions across various industries.<br/><br/>
                              As businesses continue to accumulate vast amounts of data, predictive analysis becomes increasingly valuable. It allows organizations to move from reactive to proactive strategies, anticipating trends and challenges before they occur.<br/><br/>
                              The future of predictive analysis is bright, with advancements in artificial intelligence and big data technologies promising even more accurate and sophisticated predictive models. As this field evolves, we can expect to see its integration across more industries and business functions, driving innovation and competitive advantage.     
                                </div>
                                )
                                )}
                                 data-bs-toggle="modal" data-bs-target="#exampleModal" className="text-decoration-none">
                                 Read More<i className="fa-solid fa-angle-right"></i></a>
                            </div>
                        </div>
                    </div>
                    {/* Machine Learning */}
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="services_box box3 hover-effect">
                            <figure>
                                <img src={require('../assets/images/services_box_image3.png')} alt="Machine Learning" className="img-fluid hover-effect" />
                            </figure>
                            <h5>Machine Learning</h5>
                            <p>Machine Learning (ML) is a revolutionary branch of artificial intelligence that enables computers...</p>
                            <div className="btn_wrapper">
                                <a href="#" onClick={() => handleModalContent('Machine Learning',
                                require('../assets/images/Machine_learning.jpg'), 
                                    (
                                    <div>
                                    Machine Learning (ML) is a revolutionary branch of artificial intelligence that enables computers to learn and improve from experience without being explicitly programmed. This cutting-edge technology is transforming industries and driving innovation across the globe.<br/><br/>
                                    As data volumes continue to grow exponentially, machine learning algorithms become increasingly powerful and accurate. This technology is not just changing how businesses operate; it's reshaping entire industries and creating new opportunities for innovation<br/><br/>
                                    The future of machine learning is bright, with advancements in quantum computing and neuromorphic hardware promising even more sophisticated and efficient ML systems. As this field evolves, we can expect to see its integration into more aspects of our daily lives, from smart homes to personalized healthcare
                                    </div>
                                    )
                                  
                                    )} 
                                    data-bs-toggle="modal" data-bs-target="#exampleModal" className="text-decoration-none">Read More<i className="fa-solid fa-angle-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <figure className="services_background_shape mb-0 position-absolute left_right_shape">
                        <img src={require('../assets/images/services_background_shape.png')} alt="Services Background Shape" className="img-fluid" />
                    </figure>
                </div>
                <figure className="services_right_shape mb-0 position-absolute top_bottom_shape">
                    <img src={require('../assets/images/services_right_shape.png')} alt="Services Right Shape" className="img-fluid" />
                </figure>
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={modalStyles.dialog}> {/* Apply custom styles */}
                    <div className="modal-content" style={modalStyles.content}>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">{modalContent.title}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <img src={modalContent.imgSrc} alt={modalContent.title} className="img-fluid" />
                                </div>
                                <div className="col-lg-5">
                                    <p>{modalContent.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"></button> */}
                            {/* <button type="button" className="btn btn-primary">Save changes</button>  */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
