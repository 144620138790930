import React from 'react';

import { Link } from 'react-router-dom';

const BannerSection = () => {
    return (
        <div className="banner-section-outer position-relative">
            <header>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link className="navbar-brand" to="/">
                                <figure className="mb-0">
                                <img src={require('../assets/images/Cyberbells_ai_logor.png')} alt="Cyberbells Logo" className="img-fluid" />
                                </figure>
                            </Link>
                            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#about">About</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#services">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#portfolio">Portfolio</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link scrollto" href="#contact">Contact</a>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link talk_btn" target="_blank" to="http://65.21.57.37:1031/">Let’s Talk <i className="fa-solid fa-angle-right"></i></Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>
            <section className="banner-section position-relative">
                <div className="container">
                    <div className="row">
                        {/* Best Image Column */}
                        <div className="col-lg-1 col-md-1 col-sm-1 col-1 d-md-block d-none">
                            <div className="banner_best_image position-relative">
                                <figure className="mb-0 position-absolute">
                                    <img src={require('../assets/images/best_image.png')} alt="Best Image" className="img-fluid" />
                                </figure>
                            </div>
                        </div>
                        {/* Main Content Column */}
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                            <div className="banner-section-content">
                                <h1 data-aos="fade-up">Artificial Intelligence</h1>
                                <div className="banner_content_wrapper position-relative">
                                    <p className="text-white mb-0" data-aos="fade-right">Bring Force of Artificial Intelligence To Your Business Development</p>
                                    <figure className="mb-0 position-absolute">
                                        <img src={require('../assets/images/banner_white_line.png')} alt="Banner White Line" className="img-fluid" />
                                    </figure>
                                </div>
                                <div className="btn_wrapper" data-aos="fade-up">
                                    <a className="text-decoration-none default-btn btn-size-18 hover-effect readmore_btn" href="#about">
                                        Read More<i className="fa-solid fa-angle-right"></i>
                                    </a>
                                    <a className="text-decoration-none default-btn-two btn-size-18 hover-effect" href="#contact">
                                        Contact Us<i className="fa-solid fa-angle-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Banner Image Column */}
                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="banner_image position-relative">
                                <figure className="banner_main_image mb-0 left_right_shape" id="robot">
                                    <img src={require('../assets/images/banner_image.jpg')} alt="Banner Image" />
                                </figure>
                                <figure className="banner_image_top_shape mb-0 position-absolute">
                                    <img src={require('../assets/images/banner_image_top_shape.png')} alt="Banner Image Top Shape" className="img-fluid" />
                                </figure>
                                <figure className="banner_image_bottom_shape mb-0 position-absolute top_bottom_shape">
                                    <img src={require('../assets/images/banner_image_bottom_shape.png')} alt="Banner Image Bottom Shape" className="img-fluid" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <figure className="banner_left_image mb-0 position-absolute left_right_shape">
                        <img src={require('../assets/images/banner_left_image.jpg')} alt="Banner Left Image" className="img-fluid" />
                    </figure>
                    <a href="#footer_section" className="top-btn">
                        <figure className="mb-0 position-absolute">
                            <img src={require('../assets/images/scroll_down_image.png')} alt="Scroll Down" className="img-fluid" />
                        </figure>
                    </a>
                </div>
                <figure className="banner_top_shape mb-0 position-absolute top_bottom_shape">
                    <img src={require('../assets/images/banner_top_shape.png')} alt="Banner Top Shape" className="img-fluid" />
                </figure>
               
            </section>
        </div>
    );
};

export default BannerSection;
