// AboutUsSection.js
import React from 'react';


const AboutUsSection = () => {
    return (
        <section className="aboutus_section position-relative" id="about">
            <div className="container">
                <div className="row">
                    {/* About Us Image Section */}
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="aboutus_image">
                            <figure className="aboutus_main_image mb-0">
                                <img src={require('../assets/images/aboutus_image.jpg')} alt="About Us Main Image" className="img-fluid" />
                            </figure>
                            <figure className="aboutus_image_shape position-absolute mb-0 left_right_shape">
                                <img src={require('../assets/images/aboutus_image_shape.png')} alt="About Us Image Shape" className="img-fluid" />
                            </figure>
                            <div className="image_box_wrapper hover-effect">
                                <figure className="mb-0 box_image float-left">
                                    <img src={require('../assets/images/aboutus_box_image.png')} alt="Experience Box Image" className="img-fluid hover-effect" />
                                </figure>
                                <div className="image_content_wrapper">
                                    <div className="span_wrapper">
                                        <span className="number counter">18</span>
                                        <span className="plus">+</span>
                                    </div>
                                    <p className="mb-0">Years of Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* About Us Content Section */}
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="aboutus_content" data-aos="fade-right">
                            <h6>About Us</h6>
                            <h2>Empowering Business with AI Solutions</h2>
                            <p>At Cyberbells, we are dedicated to revolutionizing business operations through cutting-edge AI solutions. Our mission is to empower organizations of all sizes with intelligent technologies that drive growth, efficiency, and innovation.</p>
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                    <p>Proven Track Record: We've successfully implemented AI solutions across various industries, driving measurable improvements for our clients.</p>
                                </li>
                                <li>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                    <p>Cutting-Edge Technology: We stay at the forefront of AI advancements, ensuring you benefit from the latest innovations.</p>
                                </li>
                                <li>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                    <p>Scalable Solutions: Our AI systems are designed to grow with your business, providing long-term value and adaptability.</p>
                                </li>
                            </ul>
                            {/* <div className="btn_wrapper">
                                <a className="text-decoration-none default-btn btn-size-18 hover-effect" href="#about">
                                    Read More<i className="fa-solid fa-angle-right"></i>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <figure className="aboutus_background_shape mb-0 position-absolute left_right_shape">
                    <img src={require('../assets/images/aboutus_background_shape.jpg')} alt="About Us Background Shape" />
                </figure>
                <figure className="aboutus_top_shape mb-0 position-absolute top_bottom_shape">
                    <img src={require('../assets/images/aboutus_top_shape.png')} alt="About Us Top Shape" className="img-fluid" />
                </figure>
            </div>
        </section>
    );
};

export default AboutUsSection;
