import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../assets/css/Faq.css'; // Ensure this file includes the custom CSS for collapse
import { Link } from 'react-router-dom';

// const FAQS_API_URL = 'http://95.216.209.46:9000/faqs';
const FAQS_API_URL = `${process.env.REACT_APP_BASE_URL}/faqs`;
// console.log(FAQS_API_URL )
const ComboSection = () => {
    const [faqs, setFaqs] = useState([]);
    const [collapsedStates, setCollapsedStates] = useState([]);

    const fetchFaqs = async () => {
        try {
            const response = await fetch(FAQS_API_URL);
            console.log(response)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setFaqs(data);
            setCollapsedStates(Array(data.length).fill(true));
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    useEffect(() => {
        fetchFaqs();
    }, []);

    const handleToggle = (index) => {
        setCollapsedStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });

        const collapseElement = document.getElementById(`collapse${index}`);
        if (collapseElement) {
            if (collapsedStates[index]) {
                collapseElement.classList.add('show');

            } else {
                collapseElement.classList.remove('show');
            }
        }
    };

    return (
        <section className="combo_section" id="faq">
           <div className="accordian-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="heading position-relative text-center">
                            <h6>FAQ’s</h6>
                            <h2>Frequently Asked Questions</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up">
                        <div className="about-service-section-text accordian-section-inner-text position-relative">
                            <div className="accordian-inner">
                                <div id="accordion1">
                                    {faqs.slice(0, Math.ceil(faqs.length / 2)).map((faq, index) => (
                                        <div className="accordion-card" key={faq._id}>
                                            <div className="card-header" id={`heading${index}`}>
                                                <button
                                                    className="btn btn-link" id="uniqueBtn"
                                                    type="button"
                                                    onClick={() => handleToggle(index)}
                                                    aria-expanded={!collapsedStates[index]}
                                                    aria-controls={`collapse${index}`}
                                                >
                                                    {faq.question}
                                                </button>
                                            </div>
                                            <div
                                                id={`collapse${index}`}
                                                className={`collapse ${!collapsedStates[index] ? 'show' : ''}`}
                                                aria-labelledby={`heading${index}`}
                                                data-bs-parent="#accordion1"
                                            >
                                                <div className="card-body">
                                                    <p className="text-size-16 text-left mb-0 p-0">
                                                        {faq.answer}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
                        <div className="about-service-section-text position-relative">
                            <div className="accordian-inner">
                                <div id="accordion2">
                                    {faqs.slice(Math.ceil(faqs.length / 2)).map((faq, index) => (
                                        <div className="accordion-card" key={faq._id}>
                                            <div className="card-header" id={`heading${index + Math.ceil(faqs.length / 2)}`}>
                                                <button
                                                    className="btn btn-link"
                                                    type="button" id="unique2Btn"
                                                    onClick={() => handleToggle(index + Math.ceil(faqs.length / 2))}
                                                    aria-expanded={!collapsedStates[index + Math.ceil(faqs.length / 2)]}
                                                    aria-controls={`collapse${index + Math.ceil(faqs.length / 2)}`}
                                                >
                                                    {faq.question}
                                                </button>
                                            </div>
                                            <div
                                                id={`collapse${index + Math.ceil(faqs.length / 2)}`}
                                                className={`collapse ${!collapsedStates[index + Math.ceil(faqs.length / 2)] ? 'show' : ''}`}
                                                aria-labelledby={`heading${index + Math.ceil(faqs.length / 2)}`}
                                                data-bs-parent="#accordion2"
                                            >
                                                <div className="card-body">
                                                    <p className="text-size-16 text-left mb-0 p-0">
                                                        {faq.answer}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <figure className="mb-0 faq_right_shape position-absolute top_bottom_shape">
                <img src={require('../assets/images/faq_right_shape.png')} alt="" className="img-fluid" />
            </figure>
            </div>

            {/* Company logos */}
            <div className="client_logos_section">
                <div className="container">
                    <div className="row" data-aos="fade-up">
                        <div className="col-lg-1 col-md-1 col-sm-6 col-12 d-md-block d-none"></div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <figure className="logo_image1 mb-0">
                                <img src={require('../assets/images/client_logo_image1.png')} alt="" className="img-fluid hover-effect" />
                            </figure>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <figure className="logo_image2 mb-0">
                                <img src={require('../assets/images/client_logo_image2.png')} alt="" className="img-fluid hover-effect" />
                            </figure>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <figure className="logo_image3 mb-0">
                                <img src={require('../assets/images/client_logo_image3.png')} alt="" className="img-fluid hover-effect" />
                            </figure>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <figure className="logo_image4 mb-0">
                                <img src={require('../assets/images/client_logo_image4.png')} alt="" className="img-fluid hover-effect" />
                            </figure>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                            <figure className="logo_image5 mb-0">
                                <img src={require('../assets/images/client_logo_image5.png')} alt="" className="img-fluid hover-effect" />
                            </figure>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-6 col-12 d-md-block d-none"></div>
                    </div>
                </div>
            </div>
            <div className="get_started_section" id="contact">
                <div className="container">
                    <div className="get_started_box">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 col-12 order-md-1 order-2">
                                <div className="get_started_box_image">
                                    <figure className="mb-0 left_right_shape">
                                        <img src={require('../assets/images/get_started_image.png')} alt="" className="img-fluid"  />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 col-12 order-md-2 order-1">
                                <div className="get_started_box_content" data-aos="fade-right">
                                    <h6>Get Started</h6>
                                    <h2>Let's Make Something Great Together</h2>
                                    <div className="btn_wrapper">
                                        <Link className="text-decoration-none default-btn-two btn-size-18 hover-effect" target="_b" to="http://65.21.57.37:1031/">Let's Talk<i className="fa-solid fa-angle-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <figure className="get_started_shape mb-0 left_right_shape">
                        <img src={require('../assets/images/get_started_shape.png')} alt="" className="img-fluid" />
                    </figure>
                </div>
            </div>
        </section>
    );
};

export default ComboSection;
