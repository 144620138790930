import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/TestimonialsSection.css';
import testimonialApostrophyShape from '../assets/images/testimonial_apostrophy_shape.png';
import testimonialBackgroundShape from '../assets/images/testimonial_background_shape.jpg';
import testimonialTopShape from '../assets/images/testimonial_top_shape.png';

// const TESTIMONIALS_API_URL = 'http://95.216.209.46:9000/testimonials';
const TESTIMONIALS_API_URL = `${process.env.REACT_APP_BASE_URL}/testimonials`;


const TestimonialsSection = () => {
    const [testimonials, setTestimonials] = useState([]);

    const fetchTestimonials = async () => {
        try {
            const response = await fetch(TESTIMONIALS_API_URL);
            console.log("Response",response)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log("data", data)
            setTestimonials(data);
        } 
        catch (error) 
        {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    useEffect(() => {
        fetchTestimonials();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dotsClass: 'slick-dots custom-dots',
        appendDots: dots => (
            <div className="slick-dots-container">
                {dots}
            </div>
        ),
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section className="testimonials_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="testimonials_content">
                            <h6>Testimonials</h6>
                            <h2>Hear it From Our Clients</h2>
                        </div>
                    </div>
                </div>
                <div className="row" data-aos="fade-up">
                    <Slider {...settings} className="portfolio_slider">
                        {testimonials.map((testimonial, index) => (
                            <div className="item" key={index}>
                                <div className="testimonials_box">
                                    <div className="testimonials_image">
                                        <figure className="mb-0">
                                            <img src={`http://localhost:9000${testimonial.image}`} alt={testimonial.author} className="img-fluid hover-effect" />
                                        </figure>
                                        <div className="testimonials_image_content_wrapper">
                                            <p className="person_name">{testimonial.author}</p>
                                            <p className="testimonials_text text-size-16 mb-0">{testimonial.position || 'Client'}</p>
                                        </div>
                                    </div>
                                    <p className="testimonials_paragraph mb-0">
                                        <span>“</span>{testimonial.content}<span>”</span>
                                    </p>
                                    <figure className="testimonial_apostrophy_shape position-absolute mb-0">
                                        <img src={testimonialApostrophyShape} alt="apostrophy" className="img-fluid hover-effect" />
                                    </figure>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <figure className="testimonials_background_shape mb-0 position-absolute left_right_shape">
                    <img src={testimonialBackgroundShape} alt="background" />
                </figure>
                <figure className="testimonials_top_shape mb-0 position-absolute top_bottom_shape">
                    <img src={testimonialTopShape} alt="top shape" className="img-fluid" />
                </figure>
            </div>
        </section>
    );
};

export default TestimonialsSection;
